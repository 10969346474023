import { FilterNestedCollection } from '@bluebase/core';
import desktop from './desktop';
import { mobile } from './mobile';

export const filters: FilterNestedCollection = {
	'mevris.app.things.layout.thing-profile-overview.desktop.main': [
		{
			key: 'inject-activiy-in-thing-profile-desktop',
			priority: 25,
			value: desktop,
		},
	],

	'mevris.app.things.routes.thing-profile.mobile': [
		{
			key: 'inject-activiy-route-in-thing-profile-mobile',
			priority: 25,
			value: mobile,
		},
	],

	// 'mevris.app.things.routes.thing-profile.desktop': [
	// 	{
	// 		key: 'inject-activiy-route-in-thing-profile-desktop',
	// 		priority: 25,
	// 		value: thingsProfileTab,
	// 	},
	// ],
};
