import BlueBasePluginApollo from '@bluebase/plugin-apollo';
import BlueBasePluginJsonSchemaComponents from '@bluebase/plugin-json-schema-components';
import BlueBasePluginLauncher from '@bluebase/plugin-launcher';
import BlueBasePluginMaterialUI from '@bluebase/plugin-material-ui';
import BlueBasePluginReactRouter from '@bluebase/plugin-react-router';
import BlueBasePluginResponsiveGrid from '@bluebase/plugin-responsive-grid';
import BlueBasePluginSettingsApp from '@bluebase/plugin-settings-app';
import BlueEastPluginUI from '@blueeast/client-plugin-ui';
import { MaterialCommunityIcons } from '@bluebase/plugin-vector-icons';
import MevrisAppThings from '@mevris/client-app-things';
import MevrisPluginTaskbar from '@mevris/client-plugin-taskbar';
import MevrisPluginUI from '@mevris/client-plugin-ui';
import MevrisPluginUserManagement from '@mevris/client-plugin-user-management';
import Plugin from '../../src/index';

export const plugins = [
	BlueBasePluginMaterialUI,
	BlueBasePluginReactRouter,
	BlueBasePluginSettingsApp,
	BlueBasePluginApollo,
	MevrisPluginTaskbar,
	BlueBasePluginJsonSchemaComponents,
	BlueBasePluginLauncher,
	BlueBasePluginResponsiveGrid,
	MaterialCommunityIcons,
	MevrisAppThings,
	MevrisPluginUI,
	BlueEastPluginUI,
	MevrisPluginUserManagement,
	Plugin,
];
