import { Noop } from '@bluebase/components';
import { plugins } from './plugins';

export default {
	configs: {
		title: 'Mevris Activity Demo',

		// 'user.accessToken':
		// 	// tslint:disable-next-line: max-line-length
		// 	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiY3JlYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwidXBkYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMS4yNTNaIiwiaWQiOiI1Y2RiYzBmZDA4NWIxYzIxOGVmZTQ5MWQiLCJmaXJzdE5hbWUiOiJBbWFhciIsImxhc3ROYW1lIjoiSGFzc2FuIiwiZW1haWwiOiJhbWFhcmhhc3NhbmNzQGdtYWlsLmNvbSIsImVtYWlsVmVyaWZpZWQiOnRydWUsImlzU29jaWFsIjpmYWxzZSwic29jaWFsUHJvdmlkZXIiOiIiLCJwaG9uZSI6IjAzMzY1Njg5ODc4IiwiYWNjZXNzVG9rZW4iOiIiLCJhdmF0YXIiOiJodHRwczovL3MzLmFtYXpvbmF3cy5jb20vbWV2cmlzLWltYWdlLWNkbi9kZWZhdWx0LXByb2ZpbGUtYXZhdGFyLnBuZyIsIm1ldGFkYXRhIjpudWxsLCJzdGF0ZSI6bnVsbCwic3RhdGVNZXRhIjpudWxsLCJoZWFydGJlYXQiOiIiLCJ2ZXJpZmllZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwiaXNEZWxldGVkIjpmYWxzZSwiZGVsZXRlZEF0IjoiIiwibGVnYWN5QWNjb3VudElkIjoiIiwic2NoZW1hIjpudWxsLCJkZWxldGVkQnkiOm51bGwsInJvbGUiOiI1Y2FjOTBiNTg1N2U3ODU5MjNkMzJhYjAifSwiaWF0IjoxNTU3OTA1NzI3LCJleHAiOjE1NTc5MDcxNjcsImF1ZCI6Im1ldnJpcy5pbyIsImlzcyI6Im1ldnJpcy5pbyJ9.3LzorQezKKN-Lm86QKGqh23qLOrTVy1SEY3OUK0Pjdo',
		// 'user.id': 'UGVyc29uOjVjZGJjMGZkMDg1YjFjMjE4ZWZlNDkxZA==',

		// // Apollo Graphql Configs
		// 'plugin.apollo.httpLinkOptions': {
		// 	uri: 'https://api-qa.mevris.io/graphql',
		// },

		'user.accessToken':
			// tslint:disable-next-line: max-line-length
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiY3JlYXRlZEF0IjoiV2VkIERlYyAyNiAyMDE4IDEyOjIyOjQzIEdNVCswNTAwIChQYWtpc3RhbiBTdGFuZGFyZCBUaW1lKSIsInVwZGF0ZWRBdCI6IjIwMTktMTItMTRUMDk6MTg6NTcuNzA0WiIsImlkIjoiNWMyMzJjNDMxOTU1MTQzOWJlMTJlNzZlIiwiZmlyc3ROYW1lIjoiRWNvbWZvcnQiLCJsYXN0TmFtZSI6IkNsYXNzaWMiLCJlbWFpbCI6ImVjb21mb3J0QGdtYWlsLmNvbSIsImVtYWlsVmVyaWZpZWQiOnRydWUsImlzU29jaWFsIjpmYWxzZSwic29jaWFsUHJvdmlkZXIiOiIiLCJwaG9uZSI6IiIsInNlY3JldCI6IiIsImF2YXRhciI6Imh0dHBzOi8vczMuYW1hem9uYXdzLmNvbS9tZXZyaXMtaW1hZ2UtY2RuL2RlZmF1bHQtcHJvZmlsZS1hdmF0YXIucG5nIiwibWV0YWRhdGEiOnt9LCJzdGF0ZSI6e30sInN0YXRlTWV0YSI6e30sImJsYWNrbGlzdCI6ZmFsc2UsImhlYXJ0YmVhdCI6IiIsInZlcmlmaWVkQXQiOiIyMDE5LTEyLTE0VDA5OjE4OjU3LjcwNFoiLCJpc0RlbGV0ZWQiOmZhbHNlLCJkZWxldGVkQXQiOiIiLCJzY2hlbWEiOm51bGwsImRlbGV0ZWRCeSI6bnVsbCwicm9sZSI6IjVjZGJmNmU2ZTE4NTBiMzA4YzA3YmI1MCJ9LCJpYXQiOjE1NzYzMTUyMTEsImV4cCI6MTU3NjMxNjY1MSwiYXVkIjoibWV2cmlzLmlvIiwiaXNzIjoibWV2cmlzLmlvIn0.bSd3sx9opL4Mk72Zqj3GOF2cPcgF4YV7Tn_yvRQFzhU',
		'user.id': 'UGVyc29uOjVjMjMyYzQzMTk1NTE0MzliZTEyZTc2ZQ==',

		// Apollo Graphql Configs
		'plugin.apollo.httpLinkOptions': {
			uri: 'https://api3.mevris.io/graphql',
		},
	},

	components: {
		ControlUI: Noop,
		MevrisUIView: Noop,
	},

	plugins,
};
