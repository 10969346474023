import { Icon, RouteConfig } from '@bluebase/components';

import { NavigationOptionsFnParam } from '../types';
import React from 'react';

export const mobile = (routes: RouteConfig[]) => {
	return [
		...routes,
		{
			name: 'ThingProfileTimeline',
			path: 'timeline',
			screen: 'ThingProfileTimelineScreen',

			navigationOptions: ({ screenProps }: NavigationOptionsFnParam) => ({
				tabBarIcon: () => <Icon name="history" color="#FFF" />,
				title: screenProps.intl.__('Timeline'),
			}),
		},
	];
};

export default mobile;
