import { VERSION } from './version';
import { components } from './components';
import { createPlugin } from '@bluebase/core';
import { filters } from './filters';
import { lang } from './lang';
import { screens } from './screens';

export default createPlugin({
	description: 'A plugin to show user activity streams in the app',
	key: '@mevris/client-plugin-activity-streams',
	name: 'Mevris Activity Streams Plugin',
	version: VERSION,

	assets: {
		EmptyTimeline_desktop_dark: require('../assets/common/empty-timeline-dark.png'),
		EmptyTimeline_mobile_dark: require('../assets/common/empty-timeline-dark.png'),

		EmptyTimeline_desktop_light: require('../assets/common/empty-timeline-light.png'),
		EmptyTimeline_mobile_light: require('../assets/common/empty-timeline-light.png'),
	},

	components: {
		...components,
		...screens,
	},

	filters: {
		...lang,
		...filters,
	},
});
