import { JsonComponentNode } from '@bluebase/plugin-json-schema-components/dist/JsonSchemaParser';

export const desktop = (schema: JsonComponentNode) => ({
	...schema,
	children: [
		...schema.children!,
		{
			component: 'ThingProfileTimelineScreen',
		},
	],
});

export default desktop;
